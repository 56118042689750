import React, { useEffect, useState } from 'react'
import AuthedPage from '../../AuthedPage'

import { collection, query, where, onSnapshot } from "firebase/firestore";
import Page from '../../Page';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase-config';
import { Box, Paper, Typography } from '@mui/material';

// THANK GOODNESS FOR https://stackoverflow.com/a/64365185
const Expire = (props: { delay: number; children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, props.delay);
    return () => clearTimeout(timer)
  }, [props.delay]);

  return visible ? <div>{props.children}</div> : <div />;
};

function EventsLog({ isAuth, signUserOut, userData }: { isAuth?: boolean, signUserOut?: Function, userData?: any }) {
  const { eventID } = useParams();
  const [messages, setMessages] = useState<Array<any>>([]);

  const ago = (sec: number) => {
    let now = new Date();
    let ago = new Date(now.getTime() - sec*1000);
    return ago.valueOf();
  }

  const [lookback, setLookback] = useState<any>(ago(10));
  useEffect(() => {
    const q = query(collection(db, "eventMessages"), where("event", "==", eventID), where("_time", ">=", lookback));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const msgs: any[] = [];
      querySnapshot.forEach((doc) => {
          let a = "pressed a button that caused an error!";
          switch (doc.data().action) {
            case "@moan": {
              a = "moaned~!";
              break;
            }
            case "@drool": {
              a = "drooled~!";
              break;
            }
            case "@poppers": {
              a = "took a hit of poppers";
              break;
            }
            case "@weed": {
              a = "took a hit of weed";
              break;
            }
            case "@drink": {
              a = "took a drink";
              break;
            }
            case "@hot": {
              a = "thinks what's on screen is hot~";
              break;
            }
            case "@stroke": {
              a = "is stroking~";
              break;
            }
            case "@pawoff": {
              a = "is pawing off~";
              break;
            }
            case "@toy": {
              a = "is using a toy~";
              break;
            }
            case "@hump": {
              a = "is humping~";
              break;
            }
            case "@edge": {
              a = "is edging~!";
              break;
            }
            case "@close": {
              a = "is close~!";
              break;
            }
            case "@nocum": {
              a = "says DON'T CUM YET!";
              break;
            }
            case "@handsoff": {
              a = "says HANDS OFF~!";
              break;
            }
            case "@cum": {
              a = "came~!";
              break;
            }
            default:
              a = "broke the app!"
          }
          msgs.push(`${doc.data().who} ${a}`);
      });
      setLookback(ago(10))
      setMessages(msgs);
    });
  }, []);
  return (
    <Page title={"Event Viewer"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          gap: "1em"
        }}
      >
        {/* <Box
          id={`msg-}`}
          className="eventMsg"
          sx={{
            // padding: "1em",
            textShadow: `-5px -5px 0 #000,
            0   -5px 0 #000,
            5px -5px 0 #000,
            5px  0   0 #000,
            5px  5px 0 #000,
            0    5px 0 #000,
            -5px  5px 0 #000,
            -5px  0   0 #000;
            `
            
          }}
        >
          <Typography variant="h6">"TMP"</Typography>
        </Box> */}
        {messages.map((msg: any, idx: number) => {
          return (
            <Expire delay={10000} key={`msg-${idx}`}>
              <Box
                id={`msg-${idx}`}
                className="eventMsg"
                sx={{
                  // padding: "1em",
                  textShadow: `-5px -5px 0 #000,
                                0   -5px 0 #000,
                                5px -5px 0 #000,
                                5px  0   0 #000,
                                5px  5px 0 #000,
                                0    5px 0 #000,
                                -5px  5px 0 #000,
                                -5px  0   0 #000;
                  `,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    // padding: "1em",
                    textShadow: `-5px -5px 0 #000,
                                  0   -5px 0 #000,
                                  5px -5px 0 #000,
                                  5px  0   0 #000,
                                  5px  5px 0 #000,
                                  0    5px 0 #000,
                                  -5px  5px 0 #000,
                                  -5px  0   0 #000;
                    `,
                    fontWeight: "bold"
                  }}
                >
                  {msg}
                </Typography>
              </Box>
            </Expire>
          )
        })}
      </Box>
    </Page>
  )
}

export default EventsLog