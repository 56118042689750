import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routing from './Routing';

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#34609f',
    },
    secondary: {
      main: '#6df0c9',
    },
    background: {
      // default: '#132e47', // astra
      default: '#012e56', // msg
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        clickable: ({ ownerState, theme }) => ({
          '&:hover, &:focus': {
            // backgroundColor: ownerState.style?.backgroundColor
            backgroundColor: ownerState.id
          },
        }),
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&:hover': {
            // backgroundColor: ownerState.style?.backgroundColor
            backgroundColor: ownerState.id
          },
        })
      }
    }
  },
};

const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routing />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
