import { Alert, Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { collection, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AuthedPage from '../../AuthedPage'
import { addMessage, analytics, db } from '../../firebase-config';

function EventsPage({ isAuth, signUserOut, userData, userMsg }: { isAuth: boolean, signUserOut: Function, userData: any, userMsg?: any }) {
  const { eventID } = useParams();
  const [isInit, setIsInit] = useState<boolean>(true);
  const [isInServer, setIsInServer] = useState<boolean|null>(null);
  const [eventData, setEventData] = useState<any>();
  
  // on page load
  useEffect(() => {
    if(userData){
      if(userData.guilds){
        let gs = userData.guilds.map((g: any) => g.id);
        if(gs.length > 0){
          // get event data
          const getEventData = () => {
            const eventsCollectionRef = collection(db, "events");
            getDoc(doc(eventsCollectionRef, eventID)).then((res: any) => {
              let e = res.data();
              if(!e){
                setIsInit(false);
                return
              }
              if (gs.includes(e.guildID)){
                logEvent(analytics, 'view_event', {uid: userData.uid || "Unknown", event_id: eventID});
                setIsInServer(true);
                setEventData(e);
                
              } else {
                setIsInServer(false);
              }
              setIsInit(false);
            })
          };
          getEventData();
        }
      }
    }
  }, [userData]);

  type BtnAction = {
    tooltip: string,
    emoji: string,
    action: string,
  }
  const actions: Array<BtnAction> = [
    {
      tooltip: "Moan",
      emoji: "😩",
      action: "@moan",
    },
    {
      tooltip: "Drool",
      emoji: "🤤",
      action: "@drool",
    },
    {
      tooltip: "Took a hit of poppers",
      emoji: "🍾",
      action: "@poppers",
    },
    {
      tooltip: "Took a hit of weed",
      emoji: "🍃",
      action: "@weed",
    },
    {
      tooltip: "Took a drink",
      emoji: "🍺",
      action: "@drink",
    },
    {
      tooltip: "Think what's on screen is hot",
      emoji: "🔥",
      action: "@hot",
    },
    {
      tooltip: "Stroke",
      emoji: "👌",
      action: "@stroke",
    },
    {
      tooltip: "Pawing Off",
      emoji: "🐾",
      action: "@pawoff",
    },
    {
      tooltip: "Toy",
      emoji: "🧸",
      action: "@toy",
    },
    {
      tooltip: "Humping",
      emoji: "🐫",
      action: "@hump",
    },
    {
      tooltip: "Edging",
      emoji: "🍆",
      action: "@edge",
    },
    {
      tooltip: "Close to finishing",
      emoji: "😍",
      action: "@close",
    },
    {
      tooltip: "Don't cum yet!",
      emoji: "🚱",
      action: "@nocum",
    },
    {
      tooltip: "Hands off!",
      emoji: "👐",
      action: "@handsoff",
    },
    {
      tooltip: "Cumming",
      emoji: "💦",
      action: "@cum",
    },
    /*
      😩  - Moan
      🤤 - Drool
      🍾  - Took a hit of poppers
      🍃 - Took a hit of weed
      🍺  - Took a drink
      🔥  - Think what's on screen is hot
      👌 - Stroke
      🐾 - Pawing off
      🧸 - Toy
      🐫  - Humping
      🍆  - Edging
      😍  - Close to finishing
      🚱 - Don't cum yet!
      👐 - Hands off!
      💦 - Cumming 
    */
  ];

  return (
    <AuthedPage isAuth={isAuth} signUserOut={signUserOut} title={"Event"} userData={userData}>
      {isInit === true ? <div>
        {/* Determining if user in server still */}
        <Typography>Loading...</Typography>
      </div> : (isInServer === null ? <div>
        {/* loading finished but couldn't determine if in server somehow */}
        <Typography>An error has occurred. Please try again later.</Typography>
      </div> : (
        isInServer === true ? <div>
          {/* is in server! render stuff */}
          <Box
            sx={{
              // textAlign: "center"
              // alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {userMsg && userMsg.msgForUser.length > 0 && <>
              <Alert severity="success">{userMsg.msgForUser}</Alert>
              <div style={{ marginBottom: "1em" }} />
            </>}
            <Paper
              sx={{
                padding: "1em",
              }}
            >
              <Typography variant="h4">{eventData.guildTitle}</Typography>
              <Typography variant="h5">{eventData.guildChannelTitle}</Typography>
              {eventData.description && <Paper elevation={2} sx={{ padding: "1em" }}>
                {`> ${eventData.description}`}
              </Paper>}
              <div style={{ marginBottom: "1em" }}></div>
              <Grid container spacing={2}>
                {actions.map((i: any, idx: any) => {
                  return (
                    <Grid item xs={3} key={`action-idx-${idx}`}>
                      <Tooltip title={i.tooltip}>
                        <Button sx={{ fontSize: "2em" }} onClick={() => {
                          addMessage({ action: i.action, who: `${userData.username}#${userData.discriminator}`, event: eventID })
                          .then((result: any) => {
                            console.log(result);
                          });
                        }} variant="outlined">{i.emoji}</Button>
                      </Tooltip>
                    </Grid>
                  )
                })}
              </Grid>
              
              

            </Paper>
          </Box>
        </div> : <div>
          {/* is not in server, do an error! */}
          <Typography>Sorry, it looks like you're not in that Discord server.</Typography>
        </div>
      ))}
    </AuthedPage>
  )
}

export default EventsPage