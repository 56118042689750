import React, { useEffect, useState } from 'react'
import NavAppBar from './Components/NavAppBar'
import Page from './Page'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from './firebase-config';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

type Props = {
  children?: React.ReactNode,
  title?: string,
  container?: boolean,
  isAuth?: boolean,
  signUserOut?: Function,
  userData?: any,
}

function AuthedPage(props: Props) {
  const [userData, setUserData] = useState<any>();
  
  return (
    <div>
      <nav>
        <NavAppBar isAuth={props.isAuth || false} signUserOut={props.signUserOut} userData={props.userData} />
      </nav>
      <Page title={props.title} children={props.children} container={props.container} />
    </div>
  )
}

export default AuthedPage