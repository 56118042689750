import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Alert, Chip, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
// icons
import HomeIcon from '@mui/icons-material/Home';
import TagIcon from '@mui/icons-material/Tag';

import "./NavAppBar.css";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';

const drawerWidth = 240;

export default function NavAppBar({isAuth, signUserOut, userData}: {isAuth: boolean, signUserOut: any, userData: any}) {
  const [mgmtNavPages, setMgmtNavPages] = useState<any>([]);
  const [loadedUserData, setLoadedUserData] = useState<any>(false);
  useEffect(() => {
    // console.log(userData);
    if(!loadedUserData && userData){
      if(userData.guilds && userData.guilds.length > 0){
        let usersGuildIds = userData.guilds.map((i: any) => i.id);
        // console.log(usersGuildIds);
        const getEvents = async (guild: any) => {
          const eventsCollectionRef = collection(db, "events");
          const q = query(eventsCollectionRef, where("live", "==", true), where("guildID", "==", guild));
          const querySnapshot = await getDocs(q);
          if(querySnapshot.docs.length > 0 && querySnapshot.docs[0] !== undefined){
            let scanTotal = querySnapshot.docs.length;
            let scanCount = 0;
            querySnapshot.docs.forEach((doc: any) => {
              // let d: any = doc.data();
              let d: any = { ...doc.data(), id: doc.id}
              let r = {route: `/events/${d.id}`, text: `${d.guildChannelTitle} @ ${d.guildShortTitle || d.guildTitle}`, icon: <TagIcon />};
              // console.log(d);
              // console.log(r)
              scanCount += 1;
              setMgmtNavPages([...mgmtNavPages, r])
              if(scanCount === scanTotal){
                setLoadedUserData(true);
              }
            })
          }
        };
        usersGuildIds.forEach((g: any) => getEvents(g));
      }
    }
  }, [userData]);

  const navPages = [
    {route: '/dash', text: 'Home', icon: <HomeIcon />},
  ];

  const [open, setOpen] = React.useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawerOpen = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: "1em" }}>
      <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} enableColorOnDark>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/dash" className={"header-link"}>
              opxFAP
            </Link>{userData && ` | ${userData.username}#${userData.discriminator}`}
            {/* {userData && userData.role && <Chip sx={{ marginLeft: "1em" }} label={userData.role} />} */}
          </Typography>
          {
            !isAuth ? 
              <span>
                {/* <Button component={Link} to="/login" color="inherit">Login</Button> */}
              </span>
            :
              <span>
                <Button onClick={signUserOut} color="inherit">Log Out</Button>
              </span>
          }
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        open={open}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Divider />
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {navPages.map((navItem, index) => (
              <ListItem key={navItem.text} disablePadding>
                <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                  <ListItemIcon>
                    {navItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={navItem.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {/* <Divider /> */}
          {isAuth && mgmtNavPages.length > 0 && (<div>
            <Divider />
            <List>
              <ListItem key="mgmt-title">
                {/* <Typography>Pages</Typography> */}
              </ListItem>
              {mgmtNavPages.map((navItem: any, index: any) => (
                <ListItem key={navItem.text} disablePadding>
                  <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                    <ListItemIcon>
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navItem.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>)}
          {userData && userData.role && <>
            <Divider />
            <Alert severity="info">You have a role! You are a(n): {userData.role}</Alert>
            <div style={{ marginBottom: "1em" }} />
          </>}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
