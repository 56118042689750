import { Typography } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { analytics } from '../../firebase-config'
import Page from '../../Page'

function TermsOfService() {
  useEffect(() => {
    logEvent(analytics, 'view_tos');
  }, [])
  return (
    <Page title={"Terms of Service"}>
      <Typography variant="h2">Terms of Service</Typography>
      <Typography variant="h3">opxFAP</Typography>
      <div style={{ marginBottom: "1em" }} />
      <Typography variant="body1" gutterBottom>
        I, OddPawsX, am providing a web interface for you, the user, to share one-tap reactions to live streams.
      </Typography>
      <Typography variant="body1" gutterBottom>
        By using this application, you agree that you will try you best to have a good time watching those streams, and that you will not try to break this app.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you, the user, would like your account data erased from this app, please <a href={"https://oddpa.ws"} target="_blank" rel="noreferrer">contact me</a> for assistance. As of the time of writing this, I have not yet implemented a self-service data removal function, so requests are manual and by request. Please see the <Link to="/privacy">privacy policy</Link> for information on data collected to run this app.
      </Typography>
      <Typography variant="body1" gutterBottom>
        As you can tell, this is a super serious, super professional page containing the terms of service. I don't know what else to put here. I just made it because the Discord integration had a spot to link a Terms of Service page. It makes me feel cool, okay????
      </Typography>
    </Page>
  )
}

export default TermsOfService