import { Typography } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { analytics } from '../../firebase-config'
import Page from '../../Page'

function PrivacyPolicy() {
  useEffect(() => {
    logEvent(analytics, 'view_privacypolicy');
  }, [])
  return (
    <Page title={"Privacy Policy"}>
      <Typography variant="h2">Privacy Policy</Typography>
      <Typography variant="h3">opxFAP</Typography>
      <div style={{ marginBottom: "1em" }} />
      <Typography variant="body1" gutterBottom>
        This application performs authentication using Discord. By signing in, you are sharing your Discord basic user information (username, icon, account ID number; used to attribute actions taken in this webapp back to you), your email (for authenticating to this webapp; no emails will ever be sent to you from this application), and a list of servers you belong to (to verify you are present in servers where events are taking place; this prevents users who do not belong to a server from spamming).
      </Typography>
      <Typography variant="body1" gutterBottom>
        This application or its owner will never share your data with third parties, with the only exception being Google (to handle authentication and to collect basic metrics such as visit count).
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you, the user, have any questions or would like your account data erased from this app, please <a href={"https://oddpa.ws"} target="_blank" rel="noreferrer">contact me</a> for assistance. As of the time of writing this, I have not yet implemented a self-service data removal function, so requests are manual and by request. Please see the <Link to="/privacy">privacy policy</Link> for information on data collected to run this app.
      </Typography>
    </Page>
  )
}

export default PrivacyPolicy