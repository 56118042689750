import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import { collection, query, where, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import AuthedPage from '../AuthedPage'
import { analytics, db } from '../firebase-config';
import TagIcon from '@mui/icons-material/Tag';
import { Link } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

function Dash({ isAuth, signUserOut, userData, userMsg }: { isAuth: boolean, signUserOut: Function, userData: any, userMsg?: any }) {
  const [mgmtNavPages, setMgmtNavPages] = useState<any>([]);
  const [loadedUserData, setLoadedUserData] = useState<any>(false);
  useEffect(() => {
    // console.log(userData);
    if(!loadedUserData && userData){
      logEvent(analytics, 'view_dash', {uid: userData.uid || "Unknown"});
      if(userData.guilds && userData.guilds.length > 0){
        let usersGuildIds = userData.guilds.map((i: any) => i.id);
        // console.log(usersGuildIds);
        const getEvents = async (guild: any) => {
          const eventsCollectionRef = collection(db, "events");
          const q = query(eventsCollectionRef, where("live", "==", true), where("guildID", "==", guild));
          const querySnapshot = await getDocs(q);
          if(querySnapshot.docs.length > 0 && querySnapshot.docs[0] !== undefined){
            let scanTotal = querySnapshot.docs.length;
            let scanCount = 0;
            querySnapshot.docs.forEach((doc: any) => {
              // let d: any = doc.data();
              let d: any = { ...doc.data(), id: doc.id}
              let r = {route: `/events/${d.id}`, text: `${d.guildChannelTitle} @ ${d.guildShortTitle || d.guildTitle}`, icon: <TagIcon />};
              // console.log(d);
              // console.log(r)
              scanCount += 1;
              let newArray = [...mgmtNavPages];
              newArray.push(r)
              setMgmtNavPages(newArray);
              if(scanCount === scanTotal){
                setLoadedUserData(true);
              }
            })
          }
        };
        usersGuildIds.forEach((g: any) => getEvents(g));
      }
    }
  }, [userData]);
  return (
    <AuthedPage title={"Dashboard"} isAuth={isAuth} signUserOut={signUserOut} userData={userData}>
      <Box
        sx={{
          // textAlign: "center"
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          marginTop: "2em"
        }}
      >
        <Typography variant="h3">Welcome to the Fun Access Portal (F.A.P.)</Typography>
        <div style={{ marginBottom: "1em" }} />
        {userMsg && userMsg.msgForUser.length > 0 && <>
          <Alert severity="success">{userMsg.msgForUser}</Alert>
          <div style={{ marginBottom: "1em" }} />
        </>}
        {/* {userData && userData.role && <>
          <Alert severity="info">You have a role! You are a(n): {userData.role}</Alert>
          <div style={{ marginBottom: "1em" }} />
        </>} */}
        {mgmtNavPages.length > 0 ? <div>
          <Typography variant="h5">My Events:</Typography>
          <Grid container>
          {mgmtNavPages.map((i: any, idx: any) => {
            return (<Grid item key={`event-grid-item-${idx}`}>
              <Link to={i.route}>
                <Button variant="contained">{i.icon} {i.text}</Button>
              </Link>
            </Grid>)
          })}
          </Grid>
        </div> : <div>
          {/* no events currently available */}
          <Typography variant="h5">Looks like you have no active events (yet).</Typography>
          <Typography variant="body1">Refresh soon to see if one goes live!</Typography>
        </div>}
      </Box>
    </AuthedPage>
  )
}

export default Dash