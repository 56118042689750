import React, { useEffect, useState } from 'react'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import ProtectedRoute, { ProtectedRouteProps } from './Components/ProtectedRoute';
import Dash from './Pages/Dash';

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "./firebase-config";
import NotFound404 from './Pages/NotFound404';
import EventsPage from './Pages/Events/EventsPage';
import AuthPage from './Pages/AuthPage';
import { collection, getDoc, doc, query, where, onSnapshot } from 'firebase/firestore';
import EventsLog from './Pages/Events/EventsLog';
import PrivacyPolicy from './Pages/Help/PrivacyPolicy';
import TermsOfService from './Pages/Help/TermsOfService';

function Routing() {
  const [isAuth, setIsAuth] = useState<boolean>(JSON.parse(localStorage.getItem('isAuth') || "false") === true);
  const [userData, setUserData] = useState<any>();
  const [userMsg, setUserMsg] = useState<any>();
  // const [isAuth, setIsAuth] = useState<boolean>(true);  // FIXME: DEV
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: isAuth,
    authenticationPath: '/',
  };

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('isAuth');
      setIsAuth(false);
      window.location.pathname = "/";
    });
  };

  onAuthStateChanged(auth, (user) => {
    if (user && !userData) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      localStorage.setItem("isAuth", "true");
      const getUserData = async () => {
        const usersCollectionRef = collection(db, "users");
        
        getDoc(doc(usersCollectionRef, uid)).then((res: any) => {
          let d = res.data();
          setUserData(d);
        })

      };
      getUserData();
    } else {
      // User is signed out
      // ...
    }
  });

  useEffect(() => {
    if(userData){
      let uid = userData.uid;
      // watching for updates to userMsg
      const q = query(collection(db, "userMessages"), where("uid", "==", uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let d = doc.data();
          setUserMsg(d);
        });
      })
    }
  }, [userData])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute isAuthenticated={!isAuth} authenticationPath={'/dash'} outlet={<App />} />, // inverse protected route to redirect to /dash when auth'd
    },
    {
      path: "/dash",
      element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Dash userMsg={userMsg} isAuth={isAuth} signUserOut={signUserOut} userData={userData} />} />,
    },
    {
      path: "/events/:eventID",
      element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={<EventsPage userMsg={userMsg} isAuth={isAuth} signUserOut={signUserOut} userData={userData} />} />,
    },
    {
      path: "/events/:eventID/log",
      element: <EventsLog />,
    },
    {
      path: "/auth",
      element: <AuthPage setIsAuth={setIsAuth} />,
    },
    {
      path: "/privacy",
      element: <PrivacyPolicy />
    },
    {
      path: "/terms",
      element: <TermsOfService />
    },
    {
      path: "*",
      element: <NotFound404 isAuth={isAuth} />,
    }
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default Routing