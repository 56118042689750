import React, { useState } from 'react'
import { signInWithCustomToken } from "firebase/auth";
import { analytics, auth } from '../firebase-config';
import { Navigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

function AuthPage({setIsAuth}: {setIsAuth: Function}) {
    // const { token } = {token: ""};
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('token') || "";
    const [n, setN] = useState<any>();
    signInWithCustomToken(auth, token)
    .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if(user){
          setIsAuth(true);
          logEvent(analytics, 'login');
          setN(<Navigate to={'/dash'} />)
        }
        // ...
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
    });
  return (
    <div>{n}</div>
  )
}

export default AuthPage