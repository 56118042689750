import { logEvent } from 'firebase/analytics';
import React, { useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';
import Ion from './Components/Ion';
import { analytics } from './firebase-config';

function App() {
  useEffect(() => {
    logEvent(analytics, 'view_unauth_home');
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <span className="App-logo"><Ion name={'sync-outline'} fontSize="5em" /></span>
        <p>
          Sign in with Discord to continue.
        </p>
        <a
          className="App-link"
          href="https://us-central1-opxfap.cloudfunctions.net/api/auth"
          // target="_blank"
          rel="noopener noreferrer"
        >
          <button className="blue-button"><Ion name={'logo-discord'} size={'small'} />Sign In</button>
        </a>
      </header>
    </div>
  );
}

export default App;
