import { Container } from '@mui/material'
import React, { useEffect } from 'react'

import "./Page.css";



type Props = {
  children?: React.ReactNode,
  title?: string,
  container?: boolean
}

function Page(props: Props) {
  useEffect(() => {
    if (props.title && props.title?.toString().length > 0 && !props.title.toString().includes("undefined")) {
      document.title = props.title + " | opxFAP" || "opxFAP";
    }
  }, [props.title]);
  return (
    <div>  {props.container === false ? props.children : <Container>
        {props.children}
      </Container>}
    </div>
  )
}

export default Page