import React from 'react'
import AuthedPage from '../AuthedPage'
import Page from '../Page'

function NotFound404({isAuth, signUserOut}: {isAuth?: boolean, signUserOut?: Function}) {
  return (!isAuth) ? (
    <Page title={"404"} container={false}>
      404
    </Page>
  ) : (<AuthedPage isAuth={isAuth} signUserOut={signUserOut} title={"404"} container={false}>
        404
      </AuthedPage>)
}

export default NotFound404